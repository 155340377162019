import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Select, Switch,Popover } from 'antd';
import { useTranslation } from '../../../hooks/useTranslation';

import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import licenseService from '../../../services/LicenseService';
import countryService from '../../../services/CountryService';
import coursesService from '../../../services/CoursesService';
import { Cell } from 'recharts';

const Courses = () => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [modal, setModal] = useState(false);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [lcfs, setLcfs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    desc: '',
    mf: '',
    license: [],
    lcfs: [],
    notes: '',
    image: null,
    country: '',
    active: true
  });

  const getLicenses = async () => {
    try {
      const response = await licenseService.getAll();
      setLicenses(response);
    } catch (error) {
      console.error(t('Error fetching licenses:'), error);
    }
  };
  const getCourses = async () => {
    try {
      const response = await coursesService.getAll().then((res) => {
        getLicenses() 
          setCourses(res);
        
      });
      
    } catch (error) {
      console.error(t('Error fetching courses:'), error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error(t('Error fetching master franchisors:'), error);
    }
  };

  

  const getLcfs = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcfs(response);
    } catch (error) {
      console.error(t('Error fetching LCFs:'), error);
    }
  };

  const getCountries = async () => {
    try {
      const response = await countryService.getAll();
      setCountries(response);
    } catch (error) {
      console.error(t('Error fetching countries:'), error);
    }
  };
  useEffect(() => {
    getCourses();
    getMasterFranchisors();
     getLcfs();
    getCountries();
  }, []);

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        price: '',
        desc: '',
        mf: '',
        license: [],
        lcfs: [],
        notes: '',
        image: null,
        country: '',
        active: true
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : type === 'file' ? event.target.files[0] : value
    }));
  };

  const handleMultiSelectChange = (event, field) => {
    const value = Array.from(event.target.selectedOptions, option => option.value);
    setFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'license' || key === 'lcfs') {
        formData[key].forEach((id, index) => {
          formDataToSend.append(`${key}[${index}][$oid]`, id);
        });
      } else {
        formDataToSend.append(key, formData[key]);
        console.log(key, formData[key]);
      }
    });
    
    try {
      if (formData._id) {
        
        await coursesService.update(formData._id, formData);
      } else {
        await coursesService.create(formDataToSend);
      }
      getCourses();
      toggleModal();
    } catch (error) {
      console.error(t('Error saving course:'), error);
    }
  };

  const handleEdit = (course) => {
    setFormData({
      _id: course._id,
      name: course.name,
      price: course.price,
      desc: course.desc,
      country: course.country,
      license: course.license.map(l => l.$oid),
      lcfs: course.lcfs.map(l => l.$oid),
      notes: course.notes,
       active: course.active
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this course?'))) {
      try {
        await coursesService.deleteCourse(id);
        getCourses();
      } catch (error) {
        console.error(t('Error deleting course:'), error);
      }
    }
  };

  const handleToggleActive = async (id, active) => {
    try {
      await coursesService.updateCourse(id, { active });
      getCourses();
    } catch (error) {
      console.error(t('Error toggling course status:'), error);
    }
  };

  const columns = [
    {
      Header: t('Name'),
      accessor: 'name'
    },
    {
      Header: t('Country'),
      accessor: 'country',
      Cell: ({ value }) => {
        const country = countries.find(c => c._id === value);
        return country ? t(country.name) : t('N/A');
      }
    },
    {
      Header: t('Price'),
      accessor: 'price',
      Cell: ({ value, original }) => {
        const country = countries.find(c => c._id === original.country);
        return `${value} ${country ? country.currencie : ''}`;
      }
    },
    {
      Header: t('Licenses'),
      id: 'license',
      accessor: (row) => {
     const a=    licenses.length > 0 && row.license?.map(lic => {
             
            const lice = licenses.find((l) => l._id === lic.$oid);
            return lice ? <p>{lice.name }</p> :<p>{t('N/A')}</p>;
          })
        
          return <Popover content={a} title={`${row.name}`}>
            <>
    <Button style={{float:'right',marginRight:10}} className="float-right" color="info" size='sm' >{`${t("Licenses")} (${row.license?.length})`}</Button>
    </>
  </Popover>
      }
    },
    {
      Header: t('LCFs'),
      id: 'lcfs',
      accessor: (row) => {
        const a=    lcfs.length > 0 && row.lcfs?.map(lic => {
            
               const lcf = lcfs.find((l) => l._id === lic.$oid);
               return lcf ? <p>{lcf.email }</p> :<p>{t('N/A')}</p>;
             })
           
             return <Popover content={a} title={`${row.name}`}>
               <>
       <Button style={{float:'right',marginRight:10}} className="float-right" color="warning" size='sm' >{`${t("Lcfs")} (${row.lcfs?.length})`}</Button>
       </>
     </Popover>
    }
    },
    {
      Header: t('Status'),
      accessor: 'active',
      Cell: ({ value }) => (
        <Badge color={value ? 'success' : 'danger'} pill>
          {value ? t('Active') : t('Inactive')}
        </Badge>
      )
    },
    {
      Header: t('Actions'),
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>{t('Edit')}</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>{t('Delete')}</Button>
          {' '}
          <Switch
            checked={original.active}
            onChange={() => handleToggleActive(original._id, !original.active)}
          />
        </>
      )
    }
  ];

  return (
    <>
      <ComponentCard title={t('Courses ({{count}})', { count: courses.length })}>
        <Button color="primary" onClick={toggleModal} style={{marginBottom: '10px'}}>{t('Add New Course')}</Button>
       {licenses.length>0 && <ReactTable
          data={courses}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        }
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? t('Edit Course') : t('Add New Course')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">{t('Name')}</Label>
              <Input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="price">{t('Price')}</Label>
              <Input type="number" name="price" id="price" value={formData.price} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="desc">{t('Description')}</Label>
              <Input type="textarea" name="desc" id="desc" value={formData.desc} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="mf">{t('MF')}</Label>
              <Input type="select" name="country" id="country" value={formData.country} onChange={handleInputChange} required>
                <option value="">{t('Select Master Franchisor')}</option>
                {masterFranchisors.map(mf => (
                  <option key={mf.country_id} value={mf.country_id}>{mf.name} {mf.lname}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="license">{t('Licenses')}</Label>
               {/* //change this with antd select */}
              <Select 
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select Licenses"
                value={formData.license}
                onChange={(value) => setFormData({ ...formData, license: value })}
              >
                {licenses.map(license => (
                  <Select.Option key={license._id} value={license._id}>{license.name}</Select.Option>
                ))}
              </Select>

              
            </FormGroup>
            <FormGroup>
              <Label for="lcfs">{t('LCFs')}</Label>
              {/* //change this with antd select */}
              <Select 
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select LCFs"
                value={formData.lcfs}
                onChange={(value) => setFormData({ ...formData, lcfs: value })}
              >
                {lcfs.map(lcf => (
                  <Select.Option key={lcf._id} value={lcf._id}>{lcf.email}</Select.Option>
                ))} 
              </Select>
             
              
            </FormGroup>
            <FormGroup>
              <Label for="country">{t('Country')}</Label>
              <Input type="select" name="country" id="country" value={formData.country} onChange={handleInputChange} required>
                <option value="">{t('Select Country')}</option>
                {countries.map(country => (
                  <option key={country._id} value={country._id}>{country.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="notes">{t('Notes')}</Label>
              <Input type="textarea" name="notes" id="notes" value={formData.notes} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="image">{t('Image')}</Label>
              <Input type="file" name="image" id="image" onChange={handleInputChange} />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" name="active" checked={formData.active} onChange={handleInputChange} />{' '}
                {t('Active')}
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">{t('Save')}</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>{t('Cancel')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Courses;